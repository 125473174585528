<template>
    <div class="threeBarChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {
            let option = {
              xAxis: {
                type: 'category',
                data: ['复星集团', '太平金科', '杭州中华', '北京中华', '国元保险'],
                splitLine:{
                  show:false
                },
                axisLabel: {
                  color: '#61B9C8',
                  fontSize: 9
                },
              },
              tooltip: {
                trigger: 'axis',
              },
              grid: {
                top: '12%',
                left: '10%',
                right: '10%',
                bottom: '10%',
                containLabel: false
              },
              barWidth: 20,
              yAxis: {
                type: 'value',
                splitLine:{
                  show:false
                },
                axisLabel: {
                  color: '#61B9C8',
                  fontSize: 9
                },
              },
              series: [
                {
                  data: [
                    {
                      value: 20,
                      itemStyle: {
                        color: '#c2232a'
                      }
                    },
                    {
                      value: 23,
                      itemStyle: {
                        color: '#fe672c'
                      }
                    },
                    {
                      value: 6,
                      itemStyle: {
                        color: '#a262f2'
                      }
                    },
                    {
                      value: 11,
                      itemStyle: {
                        color: '#2870e8'
                      }
                    },
                    {
                      value: 9,
                      itemStyle: {
                        color: '#feed2c'
                      }
                    },
                  ],
                  type: 'bar'
                }
              ]
            };

            let myChart = this.$echarts(this.$el);
            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.threeBarChart {
    height: 100%;
    width: 100%;
}
</style>
